<template>
  <div class="home">
    <router-link to="/home/drought" class="wet-button wet-button-md">To drought</router-link>
    <router-link :to="{ name: 'rain' }" class="wet-button wet-button-md">To rain</router-link>
    <router-link :to="{ name: 'custom' }" class="wet-button wet-button-md">To custom</router-link>
    <router-link
      v-for="(route, i) in appRoutes" :key="i"
      :to="{ name: route.name }" class="wet-button wet-button-md"
    >To {{route.name}}</router-link>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  computed: {
    appRoutes() {
      return this.$router.options.routes.filter((el) => el.name === 'app')[0].children;
    },
  },
};
</script>
